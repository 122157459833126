/* global jQuery */
( function( $ ) {
	// responsive iframe backup strategy. check _media.scss p iframe
	$( 'p:has( iframe )' ).css( {
		position: 'relative',
		'padding-bottom': '56.25%',
		height: '0',
		overflow: 'hidden',
	} );
}( jQuery ) );


document.addEventListener('DOMContentLoaded', function() {
  const testimonialItems = document.querySelectorAll('.kt-testimonial-item-wrap');

  testimonialItems.forEach(testimonialItem => {
    const contentWrap = testimonialItem.querySelector('.kt-testimonial-content-wrap');
    const metaWrap = testimonialItem.querySelector('.kt-testimonial-meta-wrap');

    if (contentWrap && metaWrap) {
      contentWrap.appendChild(metaWrap);
    }
  });
});
